<header id="header" class="header d-flex align-items-center fixed-top">
    <div class="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">

      <a href="index.html" class="logo d-flex align-items-center">
        <!-- Uncomment the line below if you also wish to use an image logo -->
        <img src="assets/img/logo.png" alt="">
        <!-- <h1 class="sitename">Bootslander</h1> -->
      </a>

      <nav id="navmenu" class="navmenu">
        <ul>
          <li><span style="cursor: pointer;" routerLink="/" class="active">Accueil</span></li>
          <!-- <li><a href="#about">À propos</a></li> -->
          <li><span style="cursor: pointer;" routerLink="/terms">Termes et conditions</span></li>
          <!-- <button mat-button routerLink="/terms">Termes et conditions</button> -->

          <!-- <li><a href="#gallery">Gallerie</a></li>
          <li><a href="#team">Equipe</a></li> -->

          <!-- <li><a href="#pricing">Nos prix</a></li> -->

          <!-- <li class="dropdown"><a href="#"><span>Dropdown</span> <i class="bi bi-chevron-down toggle-dropdown"></i></a>
            <ul>
              <li><a href="#">Dropdown 1</a></li>
              <li class="dropdown"><a href="#"><span>Deep Dropdown</span> <i class="bi bi-chevron-down toggle-dropdown"></i></a>
                <ul>
                  <li><a href="#">Deep Dropdown 1</a></li>
                  <li><a href="#">Deep Dropdown 2</a></li>
                  <li><a href="#">Deep Dropdown 3</a></li>
                  <li><a href="#">Deep Dropdown 4</a></li>
                  <li><a href="#">Deep Dropdown 5</a></li>
                </ul>
              </li>
              <li><a href="#">Dropdown 2</a></li>
              <li><a href="#">Dropdown 3</a></li>
              <li><a href="#">Dropdown 4</a></li>
            </ul>
          </li> -->
          
          <!-- <li><a href="#contact">Contact</a></li> -->
        </ul>
        <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
      </nav>

    </div>
  </header>


 
  