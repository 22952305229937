import { Component } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent {
  successMessage:boolean=false;


  newsletter(){

    this.successMessage=true;
    console.log('success value:',this.successMessage);
  }
}
