<footer id="footer" class="footer dark-background">

    <div class="container footer-top">
      <div class="row gy-4">
        <div class="col-lg-4 col-md-6 footer-about">
          <a href="index.html" class="logo d-flex align-items-center">
            <span class="sitename">AYOKA</span>
          </a>
          <div class="footer-contact pt-3">
            <p>Bamako, Mali</p>
            <p class="mt-3"><strong>Téléphone :</strong> <span>+223 83 49 49 43</span></p>
            <p>Email : <a href="mailto:its.dope.entreprise@gmail.com">its.dope.entreprise&#64;gmail.com</a></p>
          </div>
          <div class="social-links d-flex mt-4">
            <a href="#"><i class="bi bi-twitter-x"></i></a>
            <a href="#"><i class="bi bi-facebook"></i></a>
            <a href="#"><i class="bi bi-instagram"></i></a>
            <a href="#"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
  
        <div class="col-lg-4 col-md-3 footer-links">
          <!-- <img class="footerLogo" src="assets/img/logo.png" alt=""> -->
          <!-- <h4>Liens Utiles</h4>
          <ul>
            <li><a href="#">Accueil</a></li>
            <li><a href="#">À propos</a></li>
            <li><a href="#">Services</a></li>
            <li><a href="#">Conditions d’utilisation</a></li>
            <li><a href="#">Politique de confidentialité</a></li>
          </ul> -->
        </div>

        <div class="col-lg-4 col-md-12 footer-newsletter">
          <h4>Notre Newsletter</h4>
          <p>Abonnez-vous à notre newsletter et recevez les dernières nouvelles sur nos produits et services !</p>
          <form>
            <div class="newsletter-form"><input type="email" name="email" placeholder="Votre Email"><input type="submit" value="S'abonner"></div>
            <div class="loading">Chargement</div>
            <div class="error-message"></div>
            <div class="sent-message">Votre demande d'abonnement a été envoyée. Merci !</div>
          </form>
        </div>
  
      </div>
    </div>
  
    <div class="container copyright text-center mt-4">
      <p>©<strong class="px-1 sitename">AYOKA</strong> <span>Tous Droits Réservés</span></p>
      <!-- <div class="credits">
        Conçu par <a href="https://bootstrapmade.com/">AYOKA</a>
      </div> -->
    </div>
  
  </footer>
  