<main class="main">

  

    <!-- Hero Section -->
    <section id="hero" class="hero section dark-background">
      <img src="assets/img/hero-bg-2.jpg" alt="" class="hero-bg">

      <div class="container">
        <div class="row gy-4 justify-content-between">
          <div class="col-lg-4 order-lg-last hero-img" data-aos="zoom-out" data-aos-delay="100">
            <img src="assets/img/hero-img.png" class="img-fluid animated" alt="">
          </div>

          <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-in">
            <h1>Trouve ton coin idéal avec <span>AYOKA</span></h1>
            <p>Loues ou gères tes résidences en toute simplicité avec AYOKA. Rapide, pratique et sans stress !</p>
            <div class="d-flex">
              <a href="#about" class="btn-get-started">Commencer</a>
            </div>
          </div>
          
          

        </div>
      </div>

      <svg class="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
        <defs>
          <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g class="wave1">
          <use xlink:href="#wave-path" x="50" y="3"></use>
        </g>
        <g class="wave2">
          <use xlink:href="#wave-path" x="50" y="0"></use>
        </g>
        <g class="wave3">
          <use xlink:href="#wave-path" x="50" y="9"></use>
        </g>
      </svg>

    </section><!-- /Hero Section -->
<!-- Download Section -->
<section id="download" class="download section">

  <div class="container">

    <div class="row gy-4 text-center">

      <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
        <div class="download-item">
          <i class="bi bi-android" style="color: #3ddc84;font-size: 32px;"></i>
          <h3><a href="#" class="stretched-link">Télécharger sur Android</a></h3>
          <a href="#" class="btn btn-secondary"><i class="bi bi-google-play"></i> Google Play</a>
        </div>
      </div>

      <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="200">
        <div class="download-item">
          <i class="bi bi-apple" style="color: #000000;font-size: 32px;"></i>
          <h3><a href="#" class="stretched-link">Télécharger sur iOS</a></h3>
          <a href="#" class="btn btn-secondary"><i class="bi bi-apple"></i> App Store</a>
        </div>
      </div>

    </div>

  </div>

</section>
<!-- /Download Section -->
<!-- About Section -->
<section id="about" class="about section">
    <div class="container" data-aos="fade-up" data-aos-delay="100">
      <div class="row align-items-xl-center gy-5">
  
        <div class="col-xl-6 content">
            <h3>À Propos de AYOKA</h3>
            <h2>La solution ultime pour la location et gestion des résidences</h2>
            <p>AYOKA est une application innovante spécialement conçue pour révolutionner la gestion des propriétés résidentielles. Elle simplifie la vie des gestionnaires de résidences et améliore l'expérience des locataires grâce à une série de fonctionnalités robustes et intuitives.</p>
            <ul>
              <li><strong>Gestion des Résidences :</strong> Créez et gérez vos propriétés en toute simplicité avec des outils intuitifs pour la modification et la mise à jour des informations. Publiez vos résidences et appartements à louer directement depuis l'application, et suivez le statut d'occupation des unités en temps réel. Assurez une gestion fluide des contrats de location et des informations sur les propriétés.</li>
              <li><strong>Gestion des Visites :</strong> Planifiez et organisez les visites de vos résidences grâce à un système de réservation intégré. </li>
              <li><strong>Système de Gestion des Plaintes :</strong> Centralisez la gestion des plaintes des locataires pour une résolution rapide et efficace. Répondez directement aux préoccupations des résidents via la plateforme, améliorant ainsi leur satisfaction.</li>
              <li><strong>Gestion des Collaborateurs :</strong> Créez et gérez des comptes pour le personnel pour gérez la restauration et de sécurité. Offrez aux résidents un menu mis à jour en temps réel et  assurez un contrôle optimal des accès aux résidences avec notre système de gestion des invités.</li>
              <li><strong>Carnet de contacts :</strong> Créez un carnet d’adresses pratique avec des numéros essentiels pour vos résidents comme :<i> Le Service d'Urgence, Plombier, Électricien, Gestionnaire de Propriétés, Numéro d’Information Locale etc...</i> accessible directement depuis l’application.</li>
              <li><strong>Tourisme :</strong> Recommandez des lieux d’intérêt à vos résidents pour enrichir leur expérience dans leur nouvelle résidence.</li>
            </ul>
            <p>AYOKA est votre solution complète pour une gestion efficace, fluide et moderne des résidences. Téléchargez l'application dès aujourd'hui pour découvrir toutes ses fonctionnalités et optimiser la gestion de vos propriétés.</p>
            <!-- <a href="#" class="read-more"><span>Télécharger l'application</span><i class="bi bi-arrow-right"></i></a> -->
          </div>
          
  
        <div class="col-xl-6">
          <div class="row gy-4 icon-boxes">
  
            <div class="col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div class="icon-box">
                <i class="bi bi-buildings"></i>
                <h3>Gestion des Résidences</h3>
                
              </div>
            </div> 
  
            <div class="col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div class="icon-box">
                <i class="bi bi-person-plus"></i>
                <h3>Gestion des Visiteurs</h3>
                
              </div>
            </div> 
  
            <div class="col-md-6" data-aos="fade-up" data-aos-delay="400">
              <div class="icon-box">
                <i class="bi bi-clipboard-pulse"></i>
                <h3>Gestion des Plaintes</h3>
               
              </div>
            </div> 
  
            <div class="col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div class="icon-box">
                <i class="bi bi-person-bounding-box"></i>
                <h3>Gestion des Collaborateurs</h3>
                
              </div>
            </div> 

            <div class="col-md-6" data-aos="fade-up" data-aos-delay="700">
              <div class="icon-box">
                <i class="bi bi-bookmark-check"></i>
                <h3>Carnet de contacts</h3>
                
              </div>
            </div> 

            <div class="col-md-6" data-aos="fade-up" data-aos-delay="800">
              <div class="icon-box">
                <i class="bi bi-map"></i>
                <h3>Tourisme</h3>
                
              </div>
            </div> 
  
          </div>
        </div>
  
      </div>
    </div>
  </section>
  <!-- /About Section -->

  


  
  
  

   <!-- Section Contact -->
<section id="contact" class="contact section">

    <div class="container section-title" data-aos="fade-up">
      <h2>Contact</h2>
      <div><span>Contactez</span> <span class="description-title"> Nous !</span></div>
    </div>
  
    <div class="container" data-aos="fade" data-aos-delay="100">
  
      <div class="row gy-4">
  
        <div class="col-lg-4">
          <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
            <i class="bi bi-geo-alt flex-shrink-0"></i>
            <div>
              <h3>Adresse</h3>
              <p>Bamako, Mali</p>
            </div>
          </div>
  
          <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
            <i class="bi bi-telephone flex-shrink-0"></i>
            <div>
              <h3>Appelez-nous</h3>
              <p>+223 83494943</p>
            </div>
          </div>
  
          <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
            <i class="bi bi-envelope flex-shrink-0"></i>
            <div>
              <h3>Envoyez-nous un email</h3>
              <p>its.dope.entreprise&#64;gmail.com</p>
            </div>
          </div>
  
        </div>
  
        <div class="col-lg-8">
          <form  method="post" class="php-email-form" data-aos="fade-up" data-aos-delay="200">
            <div class="row gy-4">
  
              <div class="col-md-6">
                <input type="text" name="name" class="form-control" placeholder="Votre Nom Complet" required="">
              </div>
  
              <div class="col-md-6 ">
                <input type="email" class="form-control" name="email" placeholder="Votre Email" required="">
              </div>
  
              <div class="col-md-12">
                <input type="text" class="form-control" name="subject" placeholder="Objet" required="">
              </div>
  
              <div class="col-md-12">
                <textarea class="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
              </div>
  
              <div class="col-md-12 text-center">
                <!-- <div class="loading">Chargement</div>
                <div class="error-message"></div> -->
                <div class="sent" *ngIf="successMessage">Votre message a été envoyé. Merci !</div>
  
                <button type="submit" *ngIf="!successMessage" (click)="newsletter()">Envoyer le Message</button>
              </div>
  
            </div>
          </form>
        </div>
  
      </div>
  
    </div>
  
  </section>
  


  <a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  </main>