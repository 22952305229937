

<div class="spaceH"></div>
<div class="container" *ngIf="!showPC">
    <header>
      <!-- <span class="retour" (click)="politiqueetcofi()" style="margin: auto;">Politique de Confidentialité</span><br><br> -->
      <h1>Conditions Générales d’Utilisation (CGU)</h1>
      <p>
        Bienvenue sur <strong>AYOKA</strong> ! L'utilisation de notre application est soumise à l'acceptation des présentes Conditions Générales d’Utilisation (« CGU »). 
        Nous vous invitons à les lire attentivement avant toute utilisation de la plateforme.
      </p>
    </header>
    
    <!-- Section 1 -->
    <h5>1. Objectif des Conditions Générales d’Utilisation</h5>
    <p>
      <strong>AYOKA</strong> est une application novatrice conçue pour simplifier la gestion et la location de propriétés résidentielles. 
      Les présentes CGU établissent les règles et conditions régissant l'utilisation de la plateforme par tous les utilisateurs.
    </p>
    
    <!-- Section 2 -->
    <h5>2. Acceptation des Conditions Générales d’Utilisation</h5>
    <p>
      L'accès et l'utilisation de <strong>AYOKA</strong> impliquent l'acceptation sans réserve des présentes CGU. Si vous n'acceptez pas ces conditions, veuillez vous abstenir d'utiliser la plateforme.
    </p>
    
    <!-- Section 3 -->
    <h5>3. Création de Compte & Accès</h5>
    <ul>
      <li><strong>Création de Compte :</strong> L'accès à certaines fonctionnalités nécessite la création d'un compte utilisateur. Vous vous engagez à fournir des informations véridiques.</li>
      <li><strong>Responsabilité des Identifiants :</strong> Vous êtes entièrement responsable de la confidentialité de vos identifiants de connexion. Toute activité sur votre compte sera considérée comme effectuée par vous.</li>
    </ul>
    
    <!-- Section 4 -->
    <h5>4. Fonctionnalités & Utilisation de l'Application</h5>
    <p>Les fonctionnalités offertes par AYOKA incluent :</p>
    <ul>
      <li><strong>Gestion des Propriétés</strong></li>
      <li><strong>Planification de Visites</strong> </li>
      <li><strong>Gestion des Réclamations</strong></li>
      <li><strong>Administration des Collaborateurs</strong></li>
      <li><strong>Carnet de Contacts</strong></li>
      <li><strong>Recommandations Touristiques</strong></li>
    </ul>
    <p>
      Vous vous engagez à utiliser l'application exclusivement pour des usages légaux et en conformité avec ces CGU.
    </p>
    
    <!-- Section 5 -->
    <h5>5. Méthodes de Paiement</h5>
    <p>
      Les transactions liées aux services de la plateforme sont réalisées via le service de paiement mobile <strong>ORANGE MONEY</strong>. 
    </p>
    
    <!-- Section 6 -->
    <h5>6. Propriété Intellectuelle</h5>
    <p>
      L'ensemble des contenus, marques, logos, et autres éléments présents sur <strong>AYOKA</strong> est protégé par les droits de propriété intellectuelle. 
      Toute reproduction, modification ou distribution non autorisée est strictement interdite.
    </p>
    
    <!-- Section 7 -->
    <h5>7. Limite de Responsabilité</h5>
    <p>
      <strong>AYOKA</strong> s'efforce d'assurer un service optimal et la sécurité de vos données. Toutefois :
    </p>
    <ul>
      <li>Nous ne garantissons pas que l'application sera exempte de bogues ou d'interruptions techniques.</li>
      <li>Vous êtes responsables des informations que vous saisissez ainsi que de vos actions sur la plateforme.</li>
    </ul>
    
    <!-- Section 8 -->
    <h5>8. Protection des Données Personnelles</h5>
    <p>
      La collecte, l'utilisation et la protection de vos données personnelles sont détaillées dans notre <strong (click)="politiqueetcofi()" class="pc">Politique de Confidentialité</strong>. 
      En utilisant AYOKA, vous consentez au traitement de vos données personnelles conformément à cette dernière.
    </p>
    
    <!-- Section 9 -->
    <h5>9. Résiliation d’Accès</h5>
    <p>
      Nous nous réservons le droit de suspendre ou de résilier votre accès à l'application en cas de non-respect des présentes CGU ou pour tout autre motif jugé nécessaire.
    </p>
    
    <!-- Section 10 -->
    <h5>10. Modifications des Conditions Générales</h5>
    <p>
      Nous nous réservons le droit de modifier ces CGU à tout moment. Les utilisateurs en seront informés par les moyens jugés appropriés.
    </p>
    
    <!-- Section 11 -->
    <div class="contact-section">
      <h5>11. Contact</h5>
      <p>Email : <a href="mailto:its.dope.entreprise@gmail.com">its.dope.entreprise&#64;gmail.com</a></p>
      <p>Téléphone : +223 83 49 49 43</p>
      <p>Adresse : Bamako, Mali</p>
    </div>
  </div>
  
  
  <div class="container" *ngIf="showPC">
    <header>
        <span class="retour" (click)="politiqueetcofi()">Retour</span>
        <h1>Politique de Confidentialité</h1>
        <p>
          Chez <strong>AYOKA</strong>, votre vie privée est une priorité. Nous nous engageons à protéger les données
          personnelles de nos utilisateurs et à être transparents quant à leur utilisation. Cette Politique de Confidentialité
          explique comment nous collectons, utilisons, et protégeons vos informations.
        </p>
      </header>
      

    <!-- Section 1 -->
    <section>
      <h5>1. Collecte des Informations</h5>
      <p>Nous pouvons collecter différents types d'informations lorsque vous utilisez l'application AYOKA :</p>
      <ul>
        <li><strong>Informations personnelles :</strong> Telles que votre nom, adresse électronique, numéro de téléphone ou autres informations que vous fournissez volontairement.</li>
        <li><strong>Données techniques :</strong> version de l'application</li>
      </ul>
    </section>

    <!-- Section 2 -->
    <section>
      <h5>2. Utilisation des Données Collectées</h5>
      <p>Les informations collectées sont utilisées pour :</p>
      <ul>
        <li>Améliorer et personnaliser l'expérience utilisateur.</li>
        <li>Répondre à vos demandes de support client.</li>
        <li>Fournir des mises à jour, notifications ou informations relatives à l'application.</li>
        <li>Respecter nos obligations légales et assurer la sécurité de nos utilisateurs.</li>
      </ul>
    </section>

    <!-- Section 3 -->
    <section>
      <h5>3. Partage des Informations</h5>
      <p>Nous ne vendons ni ne louons vos données à des tiers. Toutefois, nous pouvons partager vos informations dans les cas suivants :</p>
      <ul>
        <li>Avec des prestataires de services tiers agissant en notre nom, pour assurer le bon fonctionnement de l'application.</li>
        <li>Lorsque requis par la loi ou pour protéger nos droits, utilisateurs ou partenaires.</li>
        <li>En cas de fusion, acquisition ou vente d’actifs, vos données pourraient être partagées avec les parties concernées.</li>
      </ul>
    </section>

    <!-- Section 4 -->
    <section>
      <h5>4. Protection des Données</h5>
      <p>Nous mettons en place des mesures techniques et organisationnelles pour protéger vos données contre tout accès non autorisé, perte, ou destruction. Toutefois, aucune méthode de transmission ou stockage électronique n'est totalement sûre, et nous ne pouvons garantir une sécurité absolue.</p>
    </section>

    <!-- Section 5 -->
    <section>
      <h5>5. Vos Droits</h5>
      <p>En vertu des lois applicables, vous disposez des droits suivants :</p>
      <ul>
        <li>Accès à vos données personnelles.</li>
        <li>Rectification ou suppression de vos informations.</li>
        <li>Opposition à certaines utilisations de vos données.</li>
      </ul>
      <p>Pour exercer ces droits, veuillez nous contacter via les coordonnées indiquées ci-dessous.</p>
    </section>

    <!-- Section 6 -->
    <section>
      <h5>6. Cookies et Technologies Similaires</h5>
      <p>Nous utilisons des cookies pour améliorer les fonctionnalités de l'application.</p>
    </section>

    <!-- Section 7 -->
    <section>
      <h5>7. Modifications de la Politique de Confidentialité</h5>
      <p>Cette Politique de Confidentialité peut être mise à jour périodiquement. Nous vous informerons de toute modification importante via l'application ou par d'autres moyens appropriés.</p>
    </section>

    <!-- Section 8 -->
    <section>
      <h5>8. Contact</h5>
      <p>Pour toute question ou demande concernant cette Politique de Confidentialité, vous pouvez nous contacter :</p>
      <ul>
        <li>Email : <a href="mailto:its.dope.entreprise@gmail.com">its.dope.entreprise&#64;gmail.com</a></li>
        <li>Téléphone : +223 83 49 49 43</li>
        <li>Adresse : Bamako, Mali</li>
      </ul>
    </section>
  </div>

  <!-- Footer Section -->
  <footer>
    © 2024 AYOKA. Tous droits réservés.
  </footer>